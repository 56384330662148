import React from 'react'
import { LogoWrapper, DivWrapper, Text, Flex } from '../index'
import { Container } from 'reactstrap'
import { Logo } from '../../assets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faMediumM,
  faLinkedinIn
} from '@fortawesome/free-brands-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from './style'

const Footer = () => {
  const currentYear = new Date().getFullYear()
  const path = window.location.pathname
  const isHome = path === '/'
  const curruntColor = isHome ? '#1b1e21' : '#F8F9F8'
  const currentLogo = isHome
    ? `${Logo['logo_white.png']}`
    : `${Logo['logo.png']}`
  const fontColor = isHome ? 'rgb(255,255,255)' : '#707070'
  const linkColor = isHome ? 'rgb(255,255,255,0.5)' : '#707070'
  return (
    <React.Fragment>
      <DivWrapper
        minHeight="150px"
        padding="20px 0 20px 0"
        bgColor={curruntColor}
      >
        <Container>
          <Flex className="responsive-footer">
            <LogoWrapper className="footer" width="70px" source={currentLogo} />
            <Flex
              alignItems="flex-end"
              marginLeft="auto"
              flexDirection="column"
              margin="0.7em 0 0 0"
              className="responsive-social"
            >
              <Link fontcolor={fontColor} href="tel:+662-107-8852">
                02-107-8852
              </Link>
              <Link fontcolor={fontColor} href="mailto:info@nellika.co.th">
                info@nellika.co.th
              </Link>
            </Flex>
          </Flex>
          <Flex className="responsive-footer">
            <Text.H6
              className="footer"
              fontcolor={fontColor}
              margin="30px 0 0 0"
            >
              Copyright © {currentYear} Nellika Consultancy Services .co. ltd
            </Text.H6>
            <Flex
              className="responsive-social"
              margin="0.5em 0 0 0"
              marginLeft="auto"
            >
              <Link
                href="https://www.facebook.com/Nellika.co.th/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon color={linkColor} icon={faFacebookF} />
              </Link>
              <Link
                className="social"
                href="https://medium.com/nellika"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon color={linkColor} icon={faMediumM} />
              </Link>
              <Link
                className="social"
                href="https://linkedin.com/company/nellika-consulting"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon color={linkColor} icon={faLinkedinIn} />
              </Link>
              <Link
                className="social"
                href="https://www.google.co.th/maps/place/Nellika+Consulting/@13.7562366,100.5306315,17z/data=!4m8!1m2!2m1!1snellika.co.th!3m4!1s0x30e299a8b80c533d:0x328872e4e7393d2!8m2!3d13.7562366!4d100.5328202?hl=th"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon color={linkColor} icon={faMapMarkerAlt} />
              </Link>
            </Flex>
          </Flex>
        </Container>
      </DivWrapper>
    </React.Fragment>
  )
}

export default Footer
