import styled from 'styled-components'

export const Link = styled.a`
  display: block;
  font-family: 'Josefin Sans', 'Prompt', sans-serif;
  color: ${props => props.fontcolor};
  text-decoration: none;
  text-align: right;
  margin: 0.5 0 0 0;
  cursor: pointer;
  :link {
    text-decoration: none;
    color: ${props => props.fontcolor};
  }
  &.social {
    margin: 0 0 0 50px;
  }
  @media only screen and (max-width: 768px) {
    text-align: left;
  }
`
