import React from 'react'
import { Flex, Image, Section, Text, DivWrapper } from '../../components'
import { Artwork } from '../../assets'
import { Container } from 'reactstrap'

const OurMission = () => {
  return (
    <React.Fragment>
      <Section id="missions" bgColor="#1b1e21">
        <Container>
          <DivWrapper>
            <DivWrapper height="100px">
              <DivWrapper
                height="7px"
                width="50px"
                bgColor="linear-gradient(to left, #00D2FF, #3A7BD5)"
                borderRadius="5px"
                margin="0 auto 50px auto"
              />
              <Text.H3 fontcolor="#ffff" align="center">
                <DivWrapper fontcolor="#1BDBFF" display="inline-block">
                  O
                </DivWrapper>
                ur&nbsp;
                <DivWrapper fontcolor="#1BDBFF" display="inline-block">
                  M
                </DivWrapper>
                issions
              </Text.H3>
            </DivWrapper>
            <Flex
              flexDirection="column"
              height="calc(100vh - 100px)"
              width="50%"
              margin="auto"
              className="responsive-flex"
              aos="fade-up"
              aosDuration="1000"
            >
              <DivWrapper bgColor="#1b1e21">
                <Image source={`${Artwork['ourMission1.png']}`} />
                <DivWrapper index={1}>
                  <Text.H4 fontcolor="#ffff" weight={700} align="center">
                    Software that matters
                  </Text.H4>
                  <Text.H6
                    margin="25px 0 0 0 "
                    align="center"
                    fontcolor="#ffff"
                    lineHeight="1.5em"
                  >
                    Not only do we create software that works, we also make sure
                    that every products we deliver make significant improvement
                    to our clients, and their clients.
                  </Text.H6>
                </DivWrapper>
              </DivWrapper>
            </Flex>

            <Flex
              flexDirection="column"
              height="100vh"
              width="50%"
              margin="auto"
              className="responsive-flex"
              aos="fade-up"
              aosDuration="1000"
            >
              <DivWrapper bgColor="#1b1e21">
                <Image source={`${Artwork['ourMission2.png']}`} />
                <DivWrapper
                  bgColor="#1b1e21"
                  aos="fade-up"
                  aosDuration="1000"
                  index={1}
                >
                  <Text.H4 fontcolor="#ffff" weight={700} align="center">
                    One-stop solution
                  </Text.H4>
                  <Text.H6
                    margin="25px 0 0 0 "
                    align="center"
                    fontcolor="#ffff"
                    lineHeight="1.5em"
                  >
                    With our vast variety of networkings and partnerships along
                    with our technical expertise, we are confident in our
                    capability of finding the best solution to any problems of
                    your business.
                  </Text.H6>
                </DivWrapper>
              </DivWrapper>
            </Flex>

            <Flex
              flexDirection="column"
              height="100vh"
              width="50%"
              margin="auto"
              className="responsive-flex"
              aos="fade-up"
              aosDuration="1000"
            >
              <DivWrapper bgColor="#1b1e21">
                <Image source={`${Artwork['ourMission3.png']}`} />
                <DivWrapper aos="fade-up" aosDuration="1000" index={1}>
                  <Text.H4 fontcolor="#ffff" weight={700} align="center">
                    Sustainability
                  </Text.H4>
                  <Text.H6
                    margin="25px 0 0 0 "
                    align="center"
                    fontcolor="#ffff"
                    lineHeight="1.5em"
                  >
                    At Nellika, lifelong partnership is our first priority. We
                    always aim to work alongside our clients, with the most
                    technological advancement, to create the best products.
                    solutions that sustainably tackle the end-user&apos;s tasks.
                    We make sure our service supports future modification in
                    correspondence with this ever-changing tech environment.
                  </Text.H6>
                </DivWrapper>
              </DivWrapper>
            </Flex>
          </DivWrapper>
        </Container>
      </Section>
    </React.Fragment>
  )
}

export default OurMission
