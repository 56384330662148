import React, { useState, useEffect } from 'react'

import { Button, Div } from './style'
import * as Scroll from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

const ScrollToTop = () => {
  const [bgColor, setBgColor] = useState()
  const scroll = Scroll.animateScroll

  useEffect(() => {
    const path = window.location.pathname
    const isHome = path === '/'
    if (isHome) {
      return setBgColor('rgb(255, 255, 255, 0.2)')
    } else {
      return setBgColor('#0E55E2')
    }
  }, [])
  window.onscroll = function() {
    scrollFunction()
  }

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('myBtn').style.display = 'block'
    } else {
      document.getElementById('myBtn').style.display = 'none'
    }
  }

  function topFunction() {
    scroll.scrollToTop()
  }

  return (
    <Button isHome={bgColor} id="myBtn" onClick={topFunction}>
      <Div>
        <FontAwesomeIcon icon={faAngleDoubleUp} />
      </Div>
    </Button>
  )
}

const propTypes = {
  location: PropTypes.any
}

ScrollToTop.propTypes = propTypes

export default ScrollToTop
