import React from 'react'
import PropTypes from 'prop-types'
import { FlexStyle } from './style'

const Flex = props => {
  const {
    aos,
    aosDuration,
    className,
    children,
    display,
    position,
    flexDirection,
    justifyContent,
    marginLeft,
    alignItems,
    alignSelf,
    flexWrap,
    width,
    maxWidth,
    height,
    minHeight,
    maxHeight,
    margin,
    padding,
    borderLeft,
    paddingLeft,
    float,
    bgColor,
    index,
    overflow,
    grow,
    showNext
  } = props
  return (
    <FlexStyle
      data-aos={aos}
      data-aos-duration={aosDuration}
      className={className}
      display={display}
      position={position}
      padding={padding}
      flexDirection={flexDirection}
      alignSelf={alignSelf}
      justifyContent={justifyContent}
      alignItems={alignItems}
      flexWrap={flexWrap}
      width={width}
      maxWidth={maxWidth}
      height={height}
      minHeight={minHeight}
      maxHeight={maxHeight}
      margin={margin}
      borderLeft={borderLeft}
      marginLeft={marginLeft}
      paddingLeft={paddingLeft}
      bgColor={bgColor}
      index={index}
      float={float}
      overflow={overflow}
      grow={grow}
      showNext={showNext}
    >
      {children}
    </FlexStyle>
  )
}

Flex.propTypes = {
  aos: PropTypes.string,
  aosDuration: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  position: PropTypes.string,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  height: PropTypes.string,
  maxHeight: PropTypes.string,
  minHeight: PropTypes.string,
  alignSelf: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  marginLeft: PropTypes.string,
  paddingLeft: PropTypes.string,
  display: PropTypes.string,
  borderLeft: PropTypes.string,
  flexDirection: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  bgColor: PropTypes.string,
  index: PropTypes.number,
  flexWrap: PropTypes.string,
  overflow: PropTypes.string,
  float: PropTypes.string,
  grow: PropTypes.number,
  showNext: PropTypes.bool
}

Flex.defaultProps = {
  aos: null,
  aosDuration: null,
  position: 'relative',
  className: null,
  width: null,
  height: null,
  maxHeight: null,
  minHeight: null,
  margin: null,
  padding: null,
  borderLeft: null,
  marginLeft: null,
  paddingLeft: null,
  alignSelf: null,
  display: 'flex',
  flexDirection: null,
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: null,
  grow: null,
  bgColor: null,
  index: 2,
  overflow: 'hidden',
  float: null,
  showNext: true
}

export default Flex
