import React from 'react'
import { Section, DivWrapper, Flex, Text, Image, Icon } from '../../components'
import { Logo } from '../../assets'
import { Container } from 'reactstrap'
const OurPartner = () => {
  return (
    <React.Fragment>
      <Section id="clients" padding="5em 0 5em 0" height="100%">
        <Container>
          <DivWrapper>
            <Icon.Meteor
              width="40px"
              top="25%"
              left="60%"
              transform="rotate(280deg)"
            />
            <Icon.Shuttle
              width="80px"
              top="90%"
              left="0%"
              transform="rotate(260deg)"
            />
            <DivWrapper
              height="7px"
              width="50px"
              bgColor="linear-gradient(to left, #00D2FF, #3A7BD5)"
              borderRadius="5px"
              margin="0 auto 50px auto"
            />
            <Text.H3 fontcolor="#ffff" align="center">
              Standards &amp; Technology
              <DivWrapper fontcolor="#1BDBFF" display="inline-block">
                &nbsp;Trusted&nbsp;
              </DivWrapper>
              By
            </Text.H3>
            <DivWrapper>
              <Flex margin="7em 0 0 0">
                <DivWrapper
                  aos="fade-up"
                  aosDuration="1000"
                  width="30%"
                  margin="auto"
                  align="center"
                >
                  <Image
                    filter="grayscale(100%)"
                    width="90%"
                    source={`${Logo['logo_google.png']}`}
                  />
                </DivWrapper>
                <DivWrapper
                  aos="fade-up"
                  aosDuration="1500"
                  width="30%"
                  margin="auto"
                  align="center"
                >
                  <Image
                    filter="grayscale(100%)"
                    width="80%"
                    source={`${Logo['logo_ais.png']}`}
                  />
                </DivWrapper>
                <DivWrapper
                  aos="fade-up"
                  aosDuration="2000"
                  width="30%"
                  margin="auto"
                  align="center"
                >
                  <Image
                    filter="grayscale(100%)"
                    width="50%"
                    source={`${Logo['logo_line.png']}`}
                  />
                </DivWrapper>
                <DivWrapper
                  aos="fade-up"
                  aosDuration="2500"
                  width="30%"
                  margin="auto"
                  align="center"
                >
                  <Image
                    filter="grayscale(100%)"
                    width="90%"
                    source={`${Logo['logo_kx.png']}`}
                  />
                </DivWrapper>
              </Flex>
              <Flex margin="7em 0 7em 0">
                <DivWrapper
                  aos="fade-up"
                  aosDuration="1000"
                  width="30%"
                  margin="auto"
                  align="center"
                >
                  <Image
                    filter="grayscale(100%)"
                    width="80%"
                    source={`${Logo['logo_chiiwii.png']}`}
                  />
                </DivWrapper>
                <DivWrapper
                  aos="fade-up"
                  aosDuration="1500"
                  width="30%"
                  margin="auto"
                  align="center"
                >
                  <Image
                    filter="grayscale(100%)"
                    width="50%"
                    source={`${Logo['logo_ministry.png']}`}
                  />
                </DivWrapper>
                <DivWrapper
                  aos="fade-up"
                  aosDuration="2000"
                  width="30%"
                  margin="auto"
                  align="center"
                >
                  <Image
                    filter="grayscale(100%)"
                    width="60%"
                    source={`${Logo['logo_ooca.png']}`}
                  />
                </DivWrapper>
                <DivWrapper
                  aos="fade-up"
                  aosDuration="2500"
                  width="30%"
                  margin="auto"
                  align="center"
                >
                  <Image
                    filter="grayscale(100%)"
                    width="80%"
                    source={`${Logo['logo_risland.png']}`}
                  />
                </DivWrapper>
              </Flex>
            </DivWrapper>
          </DivWrapper>
        </Container>
      </Section>
    </React.Fragment>
  )
}

export default OurPartner
