import styled from 'styled-components'

export const Div = styled.div`
  font-size: ${props => props.width};
  color: ${props => props.color};
  width: ${props => props.width};
  height: ${props => props.height};
  position: absolute;
  top: ${props => props.top};
  right: ${props => props.right};
  left: ${props => props.left};
  transform: ${props => props.transform};
  z-index: 0;
`
