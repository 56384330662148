import { Button } from 'reactstrap'
import styled from 'styled-components'

export const ButtonStyled = styled(Button)`
  background: ${props => props.bgcolor};
  color: ${props => props.color};
  border-radius: 25px;
  width: 25%;
  :hover {
    background: ${props => props.color};
    color: ${props => props.bgcolor};
    border: 1px solid ${props => props.bgcolor};
  }
`
export const FlexStyled = styled.div`
  display: flex;
  margin: 2em 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Text = styled.p`
  margin: 0 0 2em 0;
  text-align: center;
  white-space: pre-wrap;
`

export const Div = styled.div`
  margin: 1em 0 0 0;
`
