import React from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import { Home, NotFound404HomeContainer } from './containers'

const App = () => (
  <Router>
    <React.Fragment>
      <Switch>
        <Route exact path={'/'} component={Home} />
        <Route component={NotFound404HomeContainer} />
      </Switch>
    </React.Fragment>
  </Router>
)

export default App
