import styled from 'styled-components'

export const DivStyle = styled.div`
  position: ${props => props.position};
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  display: ${props => props.display};
  float: ${props => props.float};
  width: ${props => props.width};
  text-align: ${props => props.align};
  height: ${props => props.height};
  margin: ${props => props.margin};
  margin-left: ${props => props.marginLeft};
  background: ${props => props.bgColor};
  color: ${props => props.fontcolor};
  padding: ${props => props.padding};
  border-radius: ${props => props.borderRadius};
  z-index: ${props => props.index};
  overflow: ${props => props.overflow};
  font-weight: ${props => props.weight};
  &.partner {
    margin: 20px auto;
  }
  &.responsive-navbar {
    display: none;
  }
  &.form-group {
    width: 100%;
    min-height: 5.5em;
    max-height: 100%;
    margin-bottom: 0;
  }
  &.form-group2 {
    width: 100%;
    min-height: 11.5em;
    max-height: 100%;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 768px) {
    &.form-group2 {
      width: 100%;
      min-height: 160px;
      margin-bottom: 0;
      margin-top: 1em;
    }

    &.order-one {
      margin: 1em 0 0 0;
      order: 1;
      width: 100%;
    }

    &.responsive-full-width {
      margin: 1em 0 0 0;
      width: 100%;
    }

    &.responsive-image-width {
      width: 65%;
    }

    &.full-navbar {
      display: none;
    }

    &.responsive-navbar {
      display: block;
    }

    &.404page {
      height: 100%;
    }

    &.lottie {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &.text-btn404 {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 0;
      order: 2;
    }

    &.lottie2 {
      margin: -120px 0 -50px 0;
    }
  }
  &.btn404-arrow {
    animation: scroll-icon-404 1.5s infinite cubic-bezier(0.65, 0.05, 0.36, 1);
    @keyframes scroll-icon-404 {
      0% {
        transform: translateX(0px);
        opacity: 0;
      }
      30%,
      70% {
        opacity: 1;
      }
      100% {
        transform: translateX(-15px);
        opacity: 0;
      }
    }
  }
`
