import styled from 'styled-components'

export const Button = styled.button`
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  width: 50px;
  height: 50px;
  border: none;
  outline: none;
  background: ${props => props.isHome};
  color: white;
  cursor: pointer;
  z-index: 99;
  border-radius: 30px;
  :focus {
    outline: 0;
  }
`

export const Div = styled.div`
  :hover {
    animation: scroll-icon-totop 1.5s infinite cubic-bezier(0.65, 0.05, 0.36, 1);
    @keyframes scroll-icon-totop {
      0% {
        transform: translateY(10px);
        opacity: 0;
      }
      30%,
      70% {
        opacity: 1;
      }
      100% {
        transform: translateY(-10px);
        opacity: 0;
      }
    }
  }
`
