import React from 'react'
import { UlStyled, LiStyled } from './styled'
import PropTypes from 'prop-types'
const Layout = props => {
  const { children } = props
  return <React.Fragment>{children}</React.Fragment>
}

const Ul = props => {
  const { children } = props
  return <UlStyled>{children}</UlStyled>
}
const Li = props => {
  const { children, padding, margin } = props
  return (
    <LiStyled padding={padding} margin={margin}>
      {children}
    </LiStyled>
  )
}
const propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
  margin: PropTypes.string
}

Layout.propTypes = propTypes
Ul.propTypes = propTypes
Li.propTypes = propTypes

Layout.Ul = Ul
Layout.Li = Li

export default Layout
