import React, { useState, useEffect } from 'react'
import { LogoWrapper, DivWrapper, Text, Navbar } from '..'
import { Logo } from '../../assets'
import { Container } from 'reactstrap'
import { homeList } from '../../util'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

const NavBarItem = props => {
  const { fontColor, hoverColor } = props

  return (
    <DivWrapper float="right" margin="30px auto">
      {homeList.map((data, index) => (
        <DivWrapper display="inline-block" margin="0 0 0 50px" key={index}>
          <Text.Link to={data.to}>
            <Text.H6 fontcolor={fontColor} hovercolor={hoverColor}>
              {data.name}
            </Text.H6>
          </Text.Link>
        </DivWrapper>
      ))}
    </DivWrapper>
  )
}

const Header = () => {
  let history = useHistory()
  const path = window.location.pathname
  const isHome = path === '/'
  const curruntColor = isHome ? '#1b1e21' : '#F8F9F8'
  const currentLogo = isHome
    ? `${Logo['logo_white.png']}`
    : `${Logo['logo.png']}`
  const fontColor = isHome ? 'rgb(255,255,255,0.5)' : '#707070'
  const hoverColor = isHome ? '#03D7FF' : '#0E55E2'
  const [isCollapsed, setCollapsed] = useState(true)

  useEffect(() => {
    if (!isCollapsed) {
      document.body.classList.add('lock-screen')
    } else {
      document.body.classList.remove('lock-screen')
    }
  }, [isCollapsed])

  return (
    <React.Fragment>
      <DivWrapper
        id="top"
        height="150px"
        padding="50px 0 0 0"
        bgColor={curruntColor}
      >
        <Container>
          <DivWrapper width="100%" className="full-navbar">
            <LogoWrapper
              display="inline-block"
              width="90px"
              source={currentLogo}
              onClick={() =>
                history.push({
                  pathname: '/'
                })
              }
            />
            <NavBarItem fontColor={fontColor} hoverColor={hoverColor} />
          </DivWrapper>
          <DivWrapper width="100%" className="responsive-navbar">
            <LogoWrapper
              display="inline-block"
              width="90px"
              source={currentLogo}
              onClick={() =>
                history.push({
                  pathname: '/'
                })
              }
            />
            <div>
              <Navbar.Hamburger
                onClick={setCollapsed}
                isCollapsed={isCollapsed}
                isHome={isHome}
                hoverColor={hoverColor}
              />
              <Navbar.SlideDrawer
                onClick={setCollapsed}
                isCollapsed={isCollapsed}
                fontColor={fontColor}
                hoverColor={hoverColor}
              />
            </div>
          </DivWrapper>
        </Container>
      </DivWrapper>
    </React.Fragment>
  )
}

const propTypes = {
  fontColor: PropTypes.string,
  hoverColor: PropTypes.string,
  location: PropTypes.any
}

Header.propTypes = propTypes
NavBarItem.propTypes = propTypes

export default Header
