import React from 'react'
import { DivWrapper, Text, Button, Flex, LogoWrapper } from '../../components'
import { useHistory } from 'react-router-dom'
import { Container } from 'reactstrap'
import Lottie from 'react-lottie'
import { LottieArt, Logo } from '../../assets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'

const Section404NotFoundHome = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieArt['404home.json']
  }

  const history = useHistory()
  const goBack = () => {
    history.replace({ pathname: '/' })
  }

  return (
    <Container>
      <DivWrapper>
        <Flex height="100vh" width="100%">
          <LogoWrapper
            position="absolute"
            top="25px"
            left="0"
            width="90px"
            index={5}
            source={`${Logo['logo_white.png']}`}
          />
          <Flex className="content">
            <DivWrapper className="lottie" width="100%">
              <Lottie options={defaultOptions} width="100%" />
            </DivWrapper>
            <DivWrapper
              className="text-btn404"
              margin="0 0 0 100px"
              width="100%"
            >
              <Text.H1 fontcolor="#DB7433" weight={400} space="5px">
                Oops!
              </Text.H1>
              <Text.H1 fontcolor="white" weight={700} space="5px">
                404 Not Found
              </Text.H1>
              <Text.H6 fontcolor="white" margin="20px 0 0 0" space="2px">
                GROUND CONTROL TO MAJOR TOM.
              </Text.H6>
              <Text.H6 fontcolor="white" margin="20px 0 0 0" space="2px">
                YOUR CIRCUIT&apos;S DEAD.
              </Text.H6>
              <Text.H6 fontcolor="white" margin="20px 0 0 0" space="2px">
                THERE&apos;S SOMETHING WRONG.
              </Text.H6>
              <Button
                className="btn-404home"
                width="180px"
                height="50px"
                margin="20px 0 0 0 "
                bgColor="#DB7433"
                border="1px solid #DB7433"
                borderRadius="3px"
                bgColorHover="rgb(0,0,0,0)"
                paddingHover="0 0 1px 25px"
                onClick={goBack}
              >
                <DivWrapper className="btn404-arrow">
                  <FontAwesomeIcon icon={faAngleDoubleLeft} color="#DB7433" />
                </DivWrapper>
                <Text.H6
                  display="inline-block"
                  margin="0 auto"
                  fontcolor="#ffff"
                  weight={600}
                >
                  Back to Home
                </Text.H6>
              </Button>
            </DivWrapper>
          </Flex>
        </Flex>
      </DivWrapper>
    </Container>
  )
}

export default Section404NotFoundHome
