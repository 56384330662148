import React from 'react'
import { Section, DivWrapper, Text, Button, Flex } from '../../components'
import { Container } from 'reactstrap'
import { Artwork } from '../../assets'
import * as Scroll from 'react-scroll'
const scroll = Scroll.animateScroll

const Home = () => {
  function learnmore() {
    scroll.scrollTo(window.screen.height)
  }
  return (
    <React.Fragment>
      <Section
        height="calc(100vh - 150px)"
        bgImage={`${Artwork['bg1.svg']}`}
        padding="0"
      >
        <Container>
          <DivWrapper>
            <Flex height="calc(100vh - 150px)" justifyContent="flex-start">
              <DivWrapper aos="fade-right" aosDuration="1000">
                <Text.H1 fontcolor="#ffff" weight={700}>
                  Harmonizing
                </Text.H1>
                <Text.H1 fontcolor="#ffff" weight={700}>
                  Business
                </Text.H1>
                <Text.H1 fontcolor="#ffff" weight={700}>
                  And Technology
                </Text.H1>
                <Button
                  className="btn-home"
                  width="180px"
                  height="50px"
                  margin="30px 0 0 0 "
                  bgColor="linear-gradient(to right, #00D2FF, #3A7BD5)"
                  border="0"
                  borderRadius="3px"
                  bgColorHover="rgb(0,0,0,0)"
                  borderHover="1px solid #03D7FF"
                  onClick={learnmore}
                >
                  <Text.H6
                    fontcolor="white"
                    margin="auto auto"
                    align="center"
                    weight={600}
                    lineHeight="50px"
                  >
                    LEARN MORE
                  </Text.H6>
                </Button>
              </DivWrapper>
            </Flex>
          </DivWrapper>
        </Container>
      </Section>
    </React.Fragment>
  )
}

export default Home
