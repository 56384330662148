import React from 'react'
import PropTypes from 'prop-types'
import { Div } from './style'
import { Image } from '../index'
import { Icons } from '../../assets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  faGlobeAmericas,
  faMeteor,
  faUserAstronaut,
  faSpaceShuttle,
  faSun,
  faMoon,
  faSatellite
} from '@fortawesome/free-solid-svg-icons'

const Meteor = props => {
  const {
    aos,
    aosDuration,
    height,
    width,
    top,
    right,
    left,
    transform,
    color,
    opacity
  } = props
  return (
    <Div
      data-aos={aos}
      data-aos-duration={aosDuration}
      width={width}
      height={height}
      top={top}
      right={right}
      left={left}
      transform={transform}
      opacity={opacity}
    >
      <FontAwesomeIcon icon={faMeteor} color={color} />
    </Div>
  )
}

const Astronaut = props => {
  const {
    aos,
    aosDuration,
    height,
    width,
    top,
    right,
    left,
    transform,
    color,
    opacity
  } = props
  return (
    <Div
      data-aos={aos}
      data-aos-duration={aosDuration}
      width={width}
      height={height}
      top={top}
      right={right}
      left={left}
      transform={transform}
      opacity={opacity}
    >
      <FontAwesomeIcon icon={faUserAstronaut} color={color} />
    </Div>
  )
}

const BluePlanet = props => {
  const { aos, aosDuration, height, width, top, right, left, transform } = props
  return (
    <Div
      data-aos={aos}
      data-aos-duration={aosDuration}
      width={width}
      height={height}
      top={top}
      right={right}
      left={left}
      transform={transform}
    >
      <Image opacity={0.3} source={`${Icons['bg-planet-blue.svg']}`} />
    </Div>
  )
}

const Leaf = props => {
  const { aos, aosDuration, height, width, top, right, left, transform } = props
  return (
    <Div
      data-aos={aos}
      data-aos-duration={aosDuration}
      width={width}
      height={height}
      top={top}
      right={right}
      left={left}
      transform={transform}
    >
      <Image opacity={1} source={`${Icons['leaf.png']}`} />
    </Div>
  )
}

const Shuttle = props => {
  const {
    aos,
    aosDuration,
    height,
    width,
    top,
    right,
    left,
    transform,
    color,
    opacity
  } = props
  return (
    <Div
      data-aos={aos}
      data-aos-duration={aosDuration}
      width={width}
      height={height}
      top={top}
      right={right}
      left={left}
      transform={transform}
      opacity={opacity}
    >
      <FontAwesomeIcon icon={faSpaceShuttle} color={color} />
    </Div>
  )
}

const Sun = props => {
  const {
    aos,
    aosDuration,
    height,
    width,
    top,
    right,
    left,
    transform,
    color,
    opacity
  } = props
  return (
    <Div
      data-aos={aos}
      data-aos-duration={aosDuration}
      width={width}
      height={height}
      top={top}
      right={right}
      left={left}
      transform={transform}
      opacity={opacity}
    >
      <FontAwesomeIcon icon={faSun} color={color} />
    </Div>
  )
}

const Earth = props => {
  const {
    aos,
    aosDuration,
    height,
    width,
    top,
    right,
    left,
    transform,
    color,
    opacity
  } = props
  return (
    <Div
      data-aos={aos}
      data-aos-duration={aosDuration}
      width={width}
      height={height}
      top={top}
      right={right}
      left={left}
      transform={transform}
      opacity={opacity}
    >
      <FontAwesomeIcon icon={faGlobeAmericas} color={color} />
    </Div>
  )
}

const Moon = props => {
  const {
    aos,
    aosDuration,
    height,
    width,
    top,
    right,
    left,
    transform,
    color,
    opacity
  } = props
  return (
    <Div
      data-aos={aos}
      data-aos-duration={aosDuration}
      width={width}
      height={height}
      top={top}
      right={right}
      left={left}
      transform={transform}
      opacity={opacity}
    >
      <FontAwesomeIcon icon={faMoon} color={color} />
    </Div>
  )
}
const Satellite = props => {
  const {
    aos,
    aosDuration,
    height,
    width,
    top,
    right,
    left,
    transform,
    color,
    opacity
  } = props
  return (
    <Div
      data-aos={aos}
      data-aos-duration={aosDuration}
      width={width}
      height={height}
      top={top}
      right={right}
      left={left}
      transform={transform}
      opacity={opacity}
    >
      <FontAwesomeIcon icon={faSatellite} color={color} />
    </Div>
  )
}

const Icon = () => {
  return <React.Fragment></React.Fragment>
}

const propTypes = {
  aos: PropTypes.string,
  aosDuration: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  opacity: PropTypes.number,
  top: PropTypes.string,
  right: PropTypes.string,
  left: PropTypes.string,
  transform: PropTypes.string,
  color: PropTypes.string
}

const defaultProps = {
  aos: null,
  aosDuration: null,
  width: null,
  height: null,
  opacity: 1,
  top: null,
  right: null,
  left: null,
  transform: null
}

Meteor.defaultProps = defaultProps
Astronaut.defaultProps = defaultProps
BluePlanet.defaultProps = defaultProps
Shuttle.defaultProps = defaultProps
Sun.defaultProps = defaultProps
Moon.defaultProps = defaultProps
Earth.defaultProps = defaultProps
Satellite.defaultProps = defaultProps
Leaf.defaultProps = defaultProps

Meteor.propTypes = propTypes
Astronaut.propTypes = propTypes
BluePlanet.propTypes = propTypes
Shuttle.propTypes = propTypes
Sun.propTypes = propTypes
Moon.propTypes = propTypes
Earth.propTypes = propTypes
Satellite.propTypes = propTypes
Leaf.propTypes = propTypes

Icon.Meteor = Meteor
Icon.Astronaut = Astronaut
Icon.BluePlanet = BluePlanet
Icon.Shuttle = Shuttle
Icon.Sun = Sun
Icon.Moon = Moon
Icon.Earth = Earth
Icon.Satellite = Satellite
Icon.Leaf = Leaf
export default Icon
