import React from 'react'
import { Section, Text, DivWrapper, Image, Flex, Icon } from '../../components'
import { Container } from 'reactstrap'
import { Artwork } from '../../assets'

const OurServices = () => {
  return (
    <React.Fragment>
      <Section id="services">
        <Container>
          <DivWrapper>
            <Flex flexDirection="column">
              <DivWrapper
                height="7px"
                width="50px"
                bgColor="linear-gradient(to left, #00D2FF, #3A7BD5)"
                borderRadius="5px"
                margin="0 0 50px 0"
              />
              <Text.H3 fontcolor="#ffff">
                <DivWrapper fontcolor="#1BDBFF" display="inline-block">
                  O
                </DivWrapper>
                ur Services
              </Text.H3>
            </Flex>
            <Flex
              height="200vh"
              bgColor="#1b1e21"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Flex
                alignItems="center"
                height="30%"
                className="responsive-flex"
              >
                <DivWrapper
                  aos="fade-right"
                  aosDuration="1000"
                  className="responsive-image-width"
                  width="50%"
                >
                  <Image source={`${Artwork['ourServices1.png']}`} />
                </DivWrapper>
                <DivWrapper
                  aos="fade-left"
                  aosDuration="1500"
                  className="responsive-full-width"
                  width="50%"
                >
                  <Text.H4 align="center" weight={700} fontcolor="#ffff">
                    Website &amp; Application
                  </Text.H4>
                  <Text.H6
                    margin="25px 0 0 0 "
                    align="center"
                    fontcolor="#ffff"
                    lineHeight="1.5em"
                  >
                    The fact that everybody has access to mobile phones
                    motivates us to develop better mobile platforms to help our
                    clients thrive in their businesses
                  </Text.H6>
                </DivWrapper>
              </Flex>
              <Flex
                alignItems="center"
                height="30%"
                className="responsive-flex"
              >
                <DivWrapper
                  aos="fade-right"
                  aosDuration="1500"
                  className="order-one"
                  width="50%"
                >
                  <Text.H4 align="center" weight={700} fontcolor="#ffff">
                    Enterprise Software
                  </Text.H4>
                  <Text.H6
                    margin="25px 0 0 0 "
                    align="center"
                    fontcolor="#ffff"
                    lineHeight="1.5em"
                  >
                    With our business Partner in various fields, even for the
                    most challenging requirements, your completed system will be
                    delivered in time, ready for your enterprise
                  </Text.H6>
                </DivWrapper>
                <DivWrapper
                  aos="fade-left"
                  aosDuration="1500"
                  className="responsive-image-width"
                  width="50%"
                >
                  <Image source={`${Artwork['ourServices2.png']}`} />
                </DivWrapper>
              </Flex>
              <Flex
                alignItems="center"
                height="30%"
                className="responsive-flex"
              >
                <DivWrapper
                  aos="fade-right"
                  aosDuration="1000"
                  className="responsive-image-width"
                  width="50%"
                >
                  <Image source={`${Artwork['ourServices3.png']}`} />
                </DivWrapper>
                <DivWrapper
                  aos="fade-left"
                  aosDuration="1500"
                  className="responsive-full-width"
                  width="50%"
                >
                  <Text.H4 align="center" weight={700} fontcolor="#ffff">
                    AI Chatbot
                  </Text.H4>
                  <Text.H6
                    margin="25px 0 0 0 "
                    align="center"
                    fontcolor="#ffff"
                    lineHeight="1.5em"
                  >
                    Our special team of experts are secretly building AI’s
                    that’ll help us rule the world. Experts work hard to make
                    sure this technology helps ease your days.
                  </Text.H6>
                </DivWrapper>
              </Flex>
              <Icon.BluePlanet width="50%" top="45%" left="-20%" />
              <Icon.Meteor
                width="50px"
                top="10%"
                left="20%"
                transform="rotate(300deg)"
              />
              <Icon.Astronaut width="350px" top="10%" left="95%" />
              <Icon.Shuttle
                width="80px"
                top="70%"
                left="90%"
                transform="rotate(300deg)"
              />
              <Icon.Meteor width="70px" top="40%" left="40%" />
              <Icon.Meteor
                width="40px"
                top="75%"
                left="60%"
                transform="rotate(280deg)"
              />
              <Icon.Meteor width="60px" top="95%" left="90%" />
            </Flex>
          </DivWrapper>
        </Container>
      </Section>
    </React.Fragment>
  )
}

export default OurServices
