import styled from 'styled-components'
import * as Scroll from 'react-scroll'
const LinkStyle = Scroll.Link

export const TextH1Style = styled.h1`
  font-size: 3.5em;
  text-align: ${props => props.align};
  font-weight: ${props => props.weight};
  color: ${props => props.fontcolor};
  display: ${props => props.display};
  flex-direction: ${props => props.flexDirection};
  margin: ${props => props.margin};
  z-index: ${props => props.index};
  letter-spacing: ${props => props.space};
  line-height: ${props => props.lineHeight};
  transition-duration: 0.3s;
  @media only screen and (max-width: 834px) {
    font-size: 2.2em;
    &.respnsive-header {
      font-size: 2em;
      margin-top: 1em;
    }
  }
  @media screen and (max-width: 992px) and (min-width: 767px) {
    &.respnsive-header {
      font-size: 2em;
    }
  }
  @media screen and (max-width: 1200px) and (min-width: 992px) {
    &.respnsive-header {
      font-size: 3em;
    }
  }
`
export const TextH2Style = styled.h2`
  font-size: 2.8em;
  text-align: ${props => props.align};
  font-weight: ${props => props.weight};
  color: ${props => props.fontcolor};
  display: ${props => props.display};
  margin: ${props => props.margin};
  z-index: ${props => props.index};
  letter-spacing: ${props => props.space};
  line-height: ${props => props.lineHeight};
  @media only screen and (max-width: 768px) {
    font-size: 2em;
  }
`
export const TextH3Style = styled.h3`
  font-size: 2.5em;
  text-align: ${props => props.align};
  font-weight: ${props => props.weight};
  color: ${props => props.fontcolor};
  display: ${props => props.display};
  margin: ${props => props.margin};
  z-index: ${props => props.index};
  letter-spacing: ${props => props.space};
  line-height: ${props => props.lineHeight};
  @media only screen and (max-width: 768px) {
    font-size: 1.8em;
  }
`
export const TextH4Style = styled.h4`
  font-size: 1.6em;
  text-align: ${props => props.align};
  font-weight: ${props => props.weight};
  color: ${props => props.fontcolor};
  display: ${props => props.display};
  margin: ${props => props.margin};
  z-index: ${props => props.index};
  letter-spacing: ${props => props.space};
  line-height: ${props => props.lineHeight};
  @media only screen and (max-width: 767px) {
    font-size: 1.4em;
  }
`
export const TextH5Style = styled.h5`
  font-size: 1.3em;
  text-align: ${props => props.align};
  font-weight: ${props => props.weight};
  color: ${props => props.fontcolor};
  display: ${props => props.display};
  margin: ${props => props.margin};
  z-index: ${props => props.index};
  letter-spacing: ${props => props.space};
  line-height: ${props => props.lineHeight};
  transition-duration: 0.3s;
  :hover {
    color: ${props => props.hovercolor};
  }
`
export const TextH6Style = styled.h6`
  font-size: 0.9em;
  text-align: ${props => props.align};
  font-weight: ${props => props.weight};
  color: ${props => props.fontcolor};
  display: ${props => props.display};
  margin: ${props => props.margin};
  z-index: ${props => props.index};
  letter-spacing: ${props => props.space};
  line-height: ${props => props.lineHeight};
  transition-duration: 0.3s;
  :hover {
    color: ${props => props.hovercolor};
  }
  &.footer {
    @media only screen and (max-width: 767px) {
      order: 2;
      margin: 1em 0 0 0;
      font-size: 0.8em;
    }
  }
`
export const TextSpanStyle = styled.span`
  font-size: 1em;
  text-align: ${props => props.align};
  font-weight: ${props => props.weight};
  color: ${props => props.fontcolor};
`
export const TextCustomStyle = styled.h6`
  font-size: ${props => props.fontSize};
  text-align: ${props => props.align};
  font-weight: ${props => props.weight};
  color: ${props => props.fontcolor};
  margin: ${props => props.margin};
  display: ${props => props.display};
`

export const LinkAnchor = styled(LinkStyle)`
  text-decoration: none !important;
  font-size: ${props => props.fontSize}em;
  padding: ${props => props.padding};
  color: ${props => props.fontcolor};
  margin: ${props => props.margin};
  cursor: pointer;
  :hover {
    color: ${props => props.hovercolor};
  }
`
