import styled from 'styled-components'

export const FlexStyle = styled.div`
  display: ${props => props.display};
  flex-direction: ${props => props.flexDirection};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  align-self: ${props => props.alignSelf};
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
  height: ${props => props.height};
  max-height: ${props => props.maxHeight};
  min-height: ${props => props.minHeight};
  margin: ${props => props.margin};
  margin-left: ${props => props.marginLeft};
  border-left: ${props => props.borderLeft};
  padding-left: ${props => props.paddingLeft};
  background: ${props => props.bgColor};
  padding: ${props => props.padding};
  z-index: ${props => props.index};
  overflow: ${props => props.overflow};
  flex-wrap: ${props => props.flexWrap};
  float: ${props => props.float};
  transition-duration: 0.3s;
  flex-grow: ${props => props.grow};
  &.benefit-card {
    flex-grow: 1;

    :hover {
      background: white;
      box-shadow: inset 0 0 10px #99ccfd;
      -moz-box-shadow: inset 0 0 10px #99ccfd;
      -webkit-box-shadow: inset 0 0 10px #99ccfd;
    }
  }
  &.hashtag {
    height: 30px;
    border: 1px solid #ffc500;
    background: rgb(255, 197, 0, 0.1);
    margin: 0 10px 20px 10px;
    padding: 0 15px;
    border-radius: 30px;
  }
  &.responsive-flex-des {
    max-width: 50%;
    width: 50%;
  }

  @media only screen and (max-width: 992px) {
    &.responsive-flex {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 70%;
    }
    &.responsive-flex-benefit {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    &.benefit-card {
      width: 100%;
      padding: 2em 0;
    }
  }
  @media only screen and (max-width: 768px) {
    &.responsive-footer {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0px;
    }
    &.responsive-social {
      margin-left: 0px;
      align-items: flex-start;
    }
    &.responsive-flex-des {
      max-width: 100%;
      width: 100%;
    }
    &.content {
      flex-direction: column;
    }
  }
`
