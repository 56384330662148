import React from 'react'
import PropTypes from 'prop-types'
import { Text, Flex } from '../index'
import {
  Wrapper,
  SlideDrawerStyled,
  DivStyle,
  SpanStyle,
  TextNavStyle
} from './style'
import { homeList } from '../../util'

const NavBarItem = props => {
  const { fontColor, hoverColor, isCollapsed, onClick } = props
  return (
    <Flex margin="200px 0 0 10%" flexDirection="column" alignItems="flex-start">
      {homeList.map((data, index) => (
        <Text.Link
          margin="30px 0 0 0"
          onClick={() => onClick(!isCollapsed)}
          to={data.to}
          key={index}
        >
          <TextNavStyle fontColor={fontColor} hoverColor={hoverColor}>
            {data.name}
          </TextNavStyle>
        </Text.Link>
      ))}
    </Flex>
  )
}

const SlideDrawer = props => {
  const { onClick, isCollapsed, fontColor, hoverColor } = props
  return (
    <div>
      <Wrapper onClick={() => onClick(!isCollapsed)} collapsed={isCollapsed} />
      <SlideDrawerStyled collapsed={isCollapsed}>
        <NavBarItem
          onClick={onClick}
          collapsed={isCollapsed}
          fontColor={fontColor}
          hoverColor={hoverColor}
        />
      </SlideDrawerStyled>
    </div>
  )
}

const Hamburger = props => {
  const { onClick, isCollapsed, isHome } = props
  const bgColor = isHome ? '#ffff' : '#0E55E2'
  const collapsedColor = isHome ? '#00D2FF' : '#ffff'
  return (
    <DivStyle onClick={() => onClick(!isCollapsed)}>
      <SpanStyle
        className="one"
        bgColor={bgColor}
        hoverColor={collapsedColor}
        collapsed={isCollapsed}
      ></SpanStyle>
      <SpanStyle
        bgColor={bgColor}
        hoverColor={collapsedColor}
        collapsed={isCollapsed}
        className="two"
      ></SpanStyle>
      <SpanStyle
        className="three"
        bgColor={bgColor}
        hoverColor={collapsedColor}
        collapsed={isCollapsed}
      ></SpanStyle>
    </DivStyle>
  )
}

const Navbar = () => {
  return <React.Fragment />
}

const propTypes = {
  isCollapsed: PropTypes.bool,
  onClick: PropTypes.func,
  isHome: PropTypes.bool,
  fontColor: PropTypes.string,
  hoverColor: PropTypes.string
}

const defaultProps = {
  collapsed: null
}
Navbar.propTypes = propTypes
Hamburger.defaultProps = defaultProps
Hamburger.propTypes = propTypes
NavBarItem.propTypes = propTypes

SlideDrawer.defaultProps = defaultProps
SlideDrawer.propTypes = propTypes

Navbar.SlideDrawer = SlideDrawer
Navbar.Hamburger = Hamburger

export default Navbar
