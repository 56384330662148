import React from 'react'
import PropTypes from 'prop-types'
import { DivStyle } from './style'

const DivWrapper = props => {
  const {
    aos,
    aosDuration,
    className,
    id,
    children,
    position,
    top,
    right,
    left,
    bottom,
    display,
    borderRadius,
    width,
    height,
    margin,
    marginLeft,
    padding,
    align,
    bgColor,
    bgImage,
    fontcolor,
    float,
    index,
    overflow,
    weight,
    onClick,
    onScroll
  } = props
  return (
    <DivStyle
      data-aos={aos}
      data-aos-duration={aosDuration}
      className={className}
      id={id}
      position={position}
      top={top}
      right={right}
      left={left}
      bottom={bottom}
      display={display}
      borderRadius={borderRadius}
      fontcolor={fontcolor}
      float={float}
      align={align}
      width={width}
      height={height}
      margin={margin}
      marginLeft={marginLeft}
      bgColor={bgColor}
      bgImage={bgImage}
      padding={padding}
      index={index}
      overflow={overflow}
      weight={weight}
      onClick={onClick}
      onScroll={onScroll}
    >
      {children}
    </DivStyle>
  )
}

DivWrapper.propTypes = {
  aos: PropTypes.string,
  aosDuration: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
  position: PropTypes.string,
  top: PropTypes.string,
  right: PropTypes.string,
  left: PropTypes.string,
  bottom: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  align: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  borderRadius: PropTypes.string,
  display: PropTypes.string,
  fontcolor: PropTypes.string,
  bgColor: PropTypes.string,
  bgImage: PropTypes.string,
  float: PropTypes.string,
  index: PropTypes.number,
  overflow: PropTypes.string,
  marginLeft: PropTypes.string,
  weight: PropTypes.number,
  onClick: PropTypes.any,
  onScroll: PropTypes.any
}

DivWrapper.defaultProps = {
  aos: null,
  aosDuration: null,
  className: null,
  id: null,
  position: 'relative',
  top: null,
  right: null,
  left: null,
  bottom: null,
  width: null,
  height: null,
  margin: null,
  marginLeft: null,
  align: 'left',
  padding: '0',
  borderRadius: null,
  display: 'block',
  fontcolor: null,
  bgColor: null,
  bgImage: null,
  float: null,
  index: null,
  overflow: null,
  weight: null
}

export default DivWrapper
