import React from 'react'
import PropTypes from 'prop-types'

import {
  TextH1Style,
  TextH2Style,
  TextH3Style,
  TextH4Style,
  TextH5Style,
  TextH6Style,
  TextSpanStyle,
  TextCustomStyle,
  LinkAnchor
} from './style'

const H1Style = props => {
  const {
    className,
    fontcolor,
    weight,
    align,
    children,
    display,
    flexDirection,
    margin,
    index,
    space,
    lineHeight
  } = props
  return (
    <TextH1Style
      className={className}
      fontcolor={fontcolor}
      weight={weight}
      align={align}
      display={display}
      flexDirection={flexDirection}
      margin={margin}
      index={index}
      space={space}
      lineHeight={lineHeight}
    >
      {children}
    </TextH1Style>
  )
}

const H2Style = props => {
  const {
    fontcolor,
    weight,
    align,
    children,
    display,
    flexDirection,
    margin,
    index,
    space,
    lineHeight
  } = props
  return (
    <TextH2Style
      fontcolor={fontcolor}
      weight={weight}
      align={align}
      display={display}
      flexDirection={flexDirection}
      margin={margin}
      index={index}
      space={space}
      lineHeight={lineHeight}
    >
      {children}
    </TextH2Style>
  )
}

const H3Style = props => {
  const {
    fontcolor,
    weight,
    align,
    children,
    display,
    flexDirection,
    margin,
    index,
    space,
    lineHeight
  } = props
  return (
    <TextH3Style
      fontcolor={fontcolor}
      weight={weight}
      align={align}
      display={display}
      flexDirection={flexDirection}
      margin={margin}
      index={index}
      space={space}
      lineHeight={lineHeight}
    >
      {children}
    </TextH3Style>
  )
}

const H4Style = props => {
  const {
    fontcolor,
    weight,
    align,
    children,
    display,
    flexDirection,
    margin,
    index,
    space,
    lineHeight
  } = props
  return (
    <TextH4Style
      fontcolor={fontcolor}
      weight={weight}
      align={align}
      display={display}
      flexDirection={flexDirection}
      margin={margin}
      index={index}
      space={space}
      lineHeight={lineHeight}
    >
      {children}
    </TextH4Style>
  )
}

const H5Style = props => {
  const {
    className,
    fontcolor,
    weight,
    align,
    children,
    display,
    flexDirection,
    margin,
    index,
    hovercolor,
    space,
    lineHeight
  } = props
  return (
    <TextH5Style
      className={className}
      fontcolor={fontcolor}
      weight={weight}
      align={align}
      display={display}
      flexDirection={flexDirection}
      margin={margin}
      index={index}
      hovercolor={hovercolor}
      space={space}
      lineHeight={lineHeight}
    >
      {children}
    </TextH5Style>
  )
}

const H6Style = props => {
  const {
    fontcolor,
    weight,
    align,
    children,
    display,
    flexDirection,
    margin,
    index,
    hovercolor,
    space,
    lineHeight,
    className,
    id
  } = props
  return (
    <TextH6Style
      fontcolor={fontcolor}
      weight={weight}
      align={align}
      display={display}
      flexDirection={flexDirection}
      margin={margin}
      index={index}
      hovercolor={hovercolor}
      space={space}
      lineHeight={lineHeight}
      className={className}
      id={id}
    >
      {children}
    </TextH6Style>
  )
}

const SpanStyle = props => {
  const { fontcolor, weight, align, children } = props
  return (
    <TextSpanStyle fontcolor={fontcolor} weight={weight} align={align}>
      {children}
    </TextSpanStyle>
  )
}

const CustomStyle = props => {
  const {
    className,
    fontcolor,
    fontSize,
    weight,
    align,
    children,
    margin,
    display
  } = props
  return (
    <TextCustomStyle
      className={className}
      fontcolor={fontcolor}
      fontSize={fontSize}
      weight={weight}
      align={align}
      margin={margin}
      display={display}
    >
      {children}
    </TextCustomStyle>
  )
}

const Link = props => {
  const {
    children,
    onClick,
    to,
    fontSize,
    padding,
    margin,
    fontcolor,
    hovercolor,
    weight
  } = props
  return (
    <LinkAnchor
      to={to}
      onClick={onClick}
      fontSize={fontSize}
      fontcolor={fontcolor}
      padding={padding}
      margin={margin}
      hovercolor={hovercolor}
      weight={weight}
      smooth={true}
    >
      {children}
    </LinkAnchor>
  )
}

const Text = props => {
  const { children } = props
  return <React.Fragment>{children}</React.Fragment>
}

const propTypes = {
  id: PropTypes.string,
  to: PropTypes.string,
  fontcolor: PropTypes.string,
  weight: PropTypes.number,
  fontSize: PropTypes.string,
  align: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  display: PropTypes.string,
  flexDirection: PropTypes.string,
  margin: PropTypes.string,
  index: PropTypes.number,
  onClick: PropTypes.func,
  padding: PropTypes.string,
  hovercolor: PropTypes.string,
  space: PropTypes.string,
  lineHeight: PropTypes.string
}

const defaultProps = {
  className: null,
  children: null,
  align: null,
  fontcolor: '#000',
  weight: 400,
  fontSize: null,
  display: 'block',
  flexDirection: null,
  margin: null,
  index: 2,
  hovercolor: null,
  onClick: null,
  padding: null,
  space: '1px',
  lineHeight: 'normal'
}

H1Style.defaultProps = defaultProps
H2Style.defaultProps = defaultProps
H3Style.defaultProps = defaultProps
H4Style.defaultProps = defaultProps
H5Style.defaultProps = defaultProps
H6Style.defaultProps = defaultProps
Link.defaultProps = defaultProps
SpanStyle.defaultProps = defaultProps

H1Style.propTypes = propTypes
H2Style.propTypes = propTypes
H3Style.propTypes = propTypes
H4Style.propTypes = propTypes
H5Style.propTypes = propTypes
H6Style.propTypes = propTypes
SpanStyle.propTypes = propTypes
CustomStyle.propTypes = propTypes
Link.propTypes = propTypes
Text.propTypes = propTypes

Text.H1 = H1Style
Text.H2 = H2Style
Text.H3 = H3Style
Text.H4 = H4Style
Text.H5 = H5Style
Text.H6 = H6Style
Text.Span = SpanStyle
Text.Custom = CustomStyle
Text.Link = Link

export default Text
