function importAll(r) {
  let files = {}
  r.keys().forEach(item => {
    files[item.replace('./', '')] = r(item)
  })
  return files
}

export const Logo = importAll(
  require.context('./images/logo', false, /\.(png|jpe?g|gif|svg)$/)
)

export const Artwork = importAll(
  require.context('./images/artwork', false, /\.(png|jpe?g|gif|svg)$/)
)

export const LottieArt = importAll(
  require.context('./images/lottie', false, /\.(json)$/)
)

export const Icons = importAll(
  require.context('./images/icons', false, /\.(png|jpe?g|gif|svg)$/)
)
