import styled from 'styled-components'

export const Wrapper = styled.div`
  display: ${props => props.display};
  position: ${props => props.position};
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  width: ${props => props.width};
  height: ${props => props.height};
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    &.footer {
      width: 60px;
    }
  }
`
export const Image = styled.img`
  width: 100%;
`
