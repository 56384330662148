import React from 'react'
import PropTypes from 'prop-types'
import { ButtonStyle } from './style'

const Button = props => {
  const {
    children,
    className,
    id,
    display,
    width,
    height,
    margin,
    bgColor,
    border,
    borderRadius,
    index,
    bgColorHover,
    borderHover,
    paddingHover,
    colorHover,
    onClick,
    value
  } = props
  return (
    <ButtonStyle
      className={className}
      id={id}
      display={display}
      width={width}
      height={height}
      margin={margin}
      bgColor={bgColor}
      border={border}
      borderRadius={borderRadius}
      index={index}
      bgColorHover={bgColorHover}
      borderHover={borderHover}
      paddingHover={paddingHover}
      colorHover={colorHover}
      onClick={onClick}
      value={value}
    >
      {children}
    </ButtonStyle>
  )
}

Button.propTypes = {
  dataAos: PropTypes.string,
  dataAosDuration: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  display: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  bgColor: PropTypes.string,
  border: PropTypes.string,
  borderRadius: PropTypes.string,
  index: PropTypes.number,
  bgColorHover: PropTypes.string,
  borderHover: PropTypes.string,
  paddingHover: PropTypes.string,
  colorHover: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string
}

Button.defaultProps = {
  className: null,
  display: 'block',
  width: null,
  height: null,
  margin: null,
  bgColor: null,
  border: null,
  borderRadius: null,
  index: 2,
  bgColorHover: '',
  borderHover: null,
  colorHover: null,
  paddingHover: null
}

export default Button
