import styled from 'styled-components'
export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 80;
  top: 0;
  right: 0;
  transform: ${props =>
    props.collapsed ? 'translateX(100%)' : 'translateX(0)'};
`

export const SlideDrawerStyled = styled.div`
height: 100%
   background: #1b1e21;
   position: fixed;
   top: 0;
   right: 0;
   width: 70%;
   z-index: 90;
   box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
   transform: ${props =>
     props.collapsed ? 'translateX(100%)' : 'translateX(0)'};
  transition: transform 0.5s ease-out;
`

export const TextNavStyle = styled.h3`
  font-size: 1.8em;
  text-align: ${props => props.align};
  font-weight: ${props => props.weight};
  color: rgb(255, 255, 255);
  margin: ${props => props.margin};
  z-index: ${props => props.index};
  letter-spacing: ${props => props.space};
  transition-duration: 0.3s;
  :hover {
    color: ${props => props.hoverColor};
    padding-left: 1em;
  }
`

export const DivStyle = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  height: 70px;
  width: 33px;
  z-index: 99 !important;
  cursor: pointer;
`

export const SpanStyle = styled.span`
  display: block;
  width: 33px;
  height: 4px;
  cursor: pointer;
  margin-bottom: 5px;
  position: relative;
  z-index: 99;
  border-radius: 3px;
  transform-origin: 0px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  &.one {
    transform: ${props =>
      props.collapsed ? 'none' : 'rotate(45deg) translate(-2px,0px)'};
    background: ${props =>
      props.collapsed ? `${props.bgColor}` : `${props.hoverColor}`};
    transform-origin: ${props => (props.collapsed ? '0% 0%' : 'none')};
  }
  &.two {
    transform-origin: ${props => (props.collapsed ? '0% 100%' : 'none')};
    opacity: ${props => (props.collapsed ? '1' : '0')};
    background: ${props =>
      props.collapsed ? `${props.bgColor}` : `${props.hoverColor}`};
    transform: ${props =>
      props.collapsed ? 'none' : 'rotate(0deg) scale(0.2, 0.2)'};
  }
  &.three {
    transform: ${props =>
      props.collapsed ? 'none' : 'rotate(-45deg) translate(-5px, 0)'};
    background: ${props =>
      props.collapsed ? `${props.bgColor}` : `${props.hoverColor}`};
  }
`
