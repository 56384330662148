import styled from 'styled-components'
import { TextH6Style, TextH5Style } from '../Text/style'

export const ButtonStyle = styled.button`
  margin: ${props => [props.margin]};
  background: ${props => [props.bgColor]};
  color: ${props => [props.fontcolor]};
  border-radius: ${props => [props.radius]};
  width: ${props => [props.width]};
  height: ${props => props.height};
  border: ${props => [props.border]};
  border-radius: ${props => props.borderRadius};
  opacity: ${props => [props.opacity]};
  display: ${props => props.display};
  z-index: ${props => props.index};
  overflow: hidden;
  white-space: nowrap;
  transition-duration: 0.3s;
  cursor: pointer;
  :focus {
    outline: 0;
  }
  :hover {
    background: ${props => props.bgColorHover};
    border: ${props => props.borderHover};
    padding: ${props => props.paddingHover};
    color: ${props => props.colorHover};
  }
  &.btn-ghost {
    background: linear-gradient(-90deg, #1b1e21, #00d2ff, #3a7bd5, #1b1e21);
    background-size: 500% 500%;
    opacity: 0.2;
    border: 1px solid #00d2ff;
    :hover ${TextH5Style} {
      opacity: 1;
    }
    ${TextH6Style} {
      color: white;
    }
    :hover {
      opacity: 1;
      background: linear-gradient(to right, #00d2ff, #3a7bd5);
    }
  }
  &.btn-404home {
    display: flex;
    align-items: center;
    :hover ${TextH6Style} {
      color: #db7433;
    }
  }
  &.btn-home {
    :hover ${TextH6Style} {
      color: #03d7ff;
    }
  }
`
