import React from 'react'
import { Header, Footer } from '../../components'
import Home from './home'
import OurServices from './ourServices'
import OurMission from './ourMission'
import OurPartners from './ourPartners'
import { ScrollToTop } from '../../components'

const Index = () => {
  return (
    <React.Fragment>
      <ScrollToTop />
      <Header />
      <Home />
      <OurServices />
      <OurMission />
      <OurPartners />
      <Footer />
    </React.Fragment>
  )
}

export default Index
