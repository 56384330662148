import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Image } from './styled'

const LogoWrapper = props => {
  const {
    aos,
    aosDuration,
    className,
    display,
    position,
    width,
    height,
    top,
    left,
    right,
    bottom,
    source,
    onClick
  } = props
  return (
    <Wrapper
      data-aos={aos}
      data-aos-duration={aosDuration}
      className={className}
      display={display}
      position={position}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      width={width}
      height={height}
      onClick={onClick}
    >
      <Image src={source} width="100%" />
    </Wrapper>
  )
}

LogoWrapper.propTypes = {
  aos: PropTypes.string,
  aosDuration: PropTypes.string,
  className: PropTypes.string,
  position: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
  display: PropTypes.string,
  source: PropTypes.string,
  onClick: PropTypes.any
}

LogoWrapper.defaultProps = {
  aos: null,
  aosDuration: null,
  position: 'relative',
  className: null,
  width: null,
  height: null,
  top: null,
  left: '0',
  right: null,
  bottom: null,
  display: 'block',
  source: null,
  onClick: null
}

export default LogoWrapper
