import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.css'
import './index.css'
import App from './App'
import AOS from 'aos'
import 'aos/dist/aos.css'

AOS.init({
  startEvent: 'load'
})
ReactDOM.render(<App />, document.getElementById('root'))
