import styled, { css } from 'styled-components'

export const SectionStyle = styled.section`
  width: ${props => props.width};
  height: ${props => props.height};
  padding: ${props => props.padding};
  background-color: ${props => props.bgColor};
  overflow: ${props => props.overflow};
  ${props =>
    props.bgImage &&
    css`
      background-image: url(${props => props.bgImage});
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: bottom center;
    `};
`
