import React from 'react'
import PropTypes from 'prop-types'
import { SectionStyle } from './style'

const Section = props => {
  const {
    className,
    id,
    children,
    width,
    height,
    bgColor,
    bgImage,
    index,
    overflow,
    padding
  } = props
  return (
    <SectionStyle
      className={className}
      id={id}
      width={width}
      height={height}
      bgColor={bgColor}
      bgImage={bgImage}
      index={index}
      overflow={overflow}
      padding={padding}
    >
      {children}
    </SectionStyle>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  bgColor: PropTypes.string,
  bgImage: PropTypes.string,
  index: PropTypes.number,
  overflow: PropTypes.string,
  padding: PropTypes.string
}

Section.defaultProps = {
  children: null,
  id: null,
  className: null,
  width: '100%',
  height: '100%',
  bgColor: '#1b1e21',
  bgImage: null,
  index: 2,
  overflow: 'hidden',
  padding: '5em 0 0 0'
}

export default Section
